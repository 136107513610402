import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const VolunteerPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-4.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Volunteer</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>ReStore Volunteers</h2>
					<p>
						The ReStore’s mission is to be a fundraiser for our local Habitat for Humanity. The Rhinelander ReStore offers 
						a variety of tasks for every skill level, including cashier, donation pick-up assistant, pricing, sorting, and 
						cleaning. We welcome other service organizations and/or religious groups to volunteer in our store. Due to safety 
						regulations, all store volunteers must be at least 18 years old.
					</p>
					<p>
						The ReStore is open Monday through Friday 10:00 am to 4:00 pm and Saturdays 10:00 am to 3:00 pm. For scheduling 
						purposes, we request that volunteers commit to at least one shift per week, with a shift consisting of four hours minimum.
					</p>
					<a className="signup" href="https://www.signupgenius.com/index.cfm?go=c.SignUpSearch&eid=0DC2C9DFF5CDF8&cs=09B7BADD8FC48B137B0A64735BCE&sortby=l.title" target="_blank" rel="noopener noreferrer"><img src="https://www.signupgenius.com/images/sign-up-now3.gif" width="150" height="90" border="0" alt="Sign Up!" /></a>
					<div className="spacer"></div>
					<h2>Construction Volunteers</h2>
					<p>
						Construction volunteers help with a variety of tasks at the work site. They may install flooring, paint walls, build interior walls, hang drywall, or put finishing touches on a house.
					</p>
					<h5>Skills</h5>
					<p>
						No construction skill or building experience is necessary. All you need is a willingness to learn,
						work hard, and have a good time.
					</p>
					<h5>Groups</h5>
					<p>Please <a href="/about/contact/">contact us</a> to schedule a weekday or Saturday for your group.</p>
					<a className="signup" href="https://www.signupgenius.com/index.cfm?go=c.SignUpSearch&eid=0DC2C9DFF5CDF8&cs=09B7BADD8FC48B137B0A64735BCE&sortby=l.title" target="_blank" rel="noopener noreferrer"><img src="https://www.signupgenius.com/images/sign-up-now3.gif" width="150" height="90" border="0" alt="Sign Up!" /></a>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default VolunteerPage

export const Head = () => (
	<Seo
        title="Volunteer | Habitat For Humanity Northwoods Wisconsin"
    />
)